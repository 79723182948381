import cx from "clsx";
import Heading from "~/components/common/Heading";

interface PageTitleProps {
  centerY?: boolean;
  children: string;
  className?: string;
  marginClassName?: string;
}

const PageTitle = ({
  centerY,
  children,
  className,
  marginClassName = centerY ? "my-6" : "mt-12 mb-6",
}: PageTitleProps) => {
  return (
    <Heading
      className={cx(
        "not-prose text-3xl leading-snug lg:text-4xl lg:leading-snug",
        marginClassName,
        className
      )}
      tag="h1"
    >
      {children}
    </Heading>
  );
};

export default PageTitle;
