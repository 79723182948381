"use client";

import Button from "~/components/common/Button";
import Container from "~/components/common/Container";
import Layout from "~/components/common/Layout";
import PageTitle from "~/components/common/PageTitle";
import Paragraph from "~/components/common/Paragraph";

export default function Error({
  error,
  reset,
}: {
  error: { digest?: string } & Error;
  reset: () => void;
}) {
  return (
    <Layout>
      <Container className="pb-12">
        <PageTitle className="text-red-500">Error</PageTitle>
        <Paragraph className="text-lg">
          There was an error processing your request:
          <br />
          <span className="font-bold">{error.message}</span>
        </Paragraph>
        <Button className="mt-4" onClick={() => reset()}>
          Try Again
        </Button>
        {process.env.NODE_ENV === "development" && (
          <pre className="mt-12 text-sm">{error.stack}</pre>
        )}
      </Container>
    </Layout>
  );
}
